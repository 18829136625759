import $ from "jquery";

export default class Answer {
    constructor (question, alternative) {
        this.question = question;

        this.widget = $('<div class="alternative" ></div>');
        this.elements = {
            label: $('<div class="label"></div>'),
            icon: $('<div class="answer-icon"><i class="fa fa-chevron-right"></i></div>')
        };

        this.alternative = alternative;
        this.buildDom();
        this.attachEvents();
    }

    buildDom() {
        this.widget.append(
            this.elements.icon,
            this.elements.label
        );
        this.elements.label.text(this.alternative);
    }

    isCorrect() {
        return this.question.isCorrect(this);
    }

    attachEvents(){
        this.widget.on('click',() => {
           if (this.question.canAnswer()) {
               if (this.isCorrect()) {
                   this.setCorrect();
                   this.question.outcome(true);
               } else {
                   this.setIncorrect();
                   this.question.outcome(false);
               }
           }
        });
    }


    setCorrect() {
        this.widget.attr('data-outcome','correct');
        this.elements.icon.find('i').attr('class','fa fa-check');
    }

    setIncorrect() {
        this.widget.attr('data-outcome','incorrect');
        this.elements.icon.find('i').attr('class','fa fa-times')
    }



}