import $ from "jquery";
import Question from "./question";

export default class Category {
    constructor(categoryId, main) {
        this.categoryId = categoryId;
        this.main = main;
        this.widget = $('<div class="category"></div>');
        this.elements = {
            icon: $('<img class="category-icon">'),
            title: $('<h1 class="category-title"></h1>'),
            subtitle: $('<h2 class="category-subtitle"></h2>'),
            description: $('<div class="category-description"></div>'),
            key_messages: $('<div class="category-key-messages"></div>'),
            advice: $('<div class="category-advice"></div>'),
            startButton: $('<button>FILLO QUIZIN <i class="fa fa-arrow-right"></i></button>'),
            backButton: $('<button><i class="fa fa-arrow-left"></i> KTHEHU</button>'),
            returnButton: $('<a class="close"><i class="fa fa-times"</a>'),
            cover: $('<div class="cover"></div>')
        };

        this.data = {};

        this.questions = [];
        this.score = 0;

        this.buildDom();
        this.attachEvents();
        this.load();
    }

    buildDom() {
        this.widget.append (
            $('<div class="category-header"></div>').append(
                this.elements.icon,
                this.elements.returnButton
            ),
            $('<div class="category-titles"></div>').append(
                this.elements.title,
                this.elements.subtitle,
            ),
            $('<div class="category-content"></div>').append(
                this.elements.description,
                this.elements.key_messages.hide(),
                this.elements.advice.hide(),
                this.elements.startButton,
                this.elements.backButton.hide(),
            )
        );
    }

    updateView() {
        this.widget.attr('theme',this.categoryId);
        this.elements.icon.attr('src',`/assets/img/icon-${this.categoryId}.svg`);
        this.elements.title.text(this.data.title);
        this.elements.subtitle.text(this.data.subtitle);
        this.elements.description.html(this.data.description);
        this.elements.key_messages.html(this.data.key_messages);
        this.elements.advice.html(this.data.advice);
        this.elements.cover.attr('theme',this.categoryId);
    }

    attachEvents() {
        this.elements.returnButton.on('click',()=>{
           this.hide();
        });

        this.elements.backButton.on('click',()=>{
            this.hide();
        });

        this.elements.startButton.on('click',()=> {
            this.showCover();
            this.score = 0;
            window.setTimeout( ()=> {
                this.questions[0].show();
            }, 200);

        });
    }

    load() {
        $.get(`/category/${this.categoryId}`, (data) => {
            this.setData(JSON.parse(atob(data)));
        });
    }

    showCover() {
        $('body').append(this.elements.cover);
    }

    hideCover() {
        this.elements.cover.remove();
    }

    setData(data) {
        console.log('data',data);
        this.data = data;
        for (let question of this.data.questions) {
            let questionObj = new Question(this, question);
            this.questions.push(questionObj);
            this.elements.cover.append(questionObj.widget);
        }
        this.updateView();
        this.show()
    }

    show() {
        this.main.hide();
        $('body').append(this.widget);
    }

    hide() {
        this.main.show();
        this.widget.remove();
    }

    advance(question) {
        let newIndex = this.questions.indexOf(question) + 1;
       question.hide();
        if (newIndex < this.questions.length) {
            this.questions[newIndex].show();
        } else {
            this.showOutcome();
        }
    }

    showOutcome(){
        this.hideCover();

        let qTotal = this.questions.length;
        let qCorrect = 0;
        for (let question of this.questions) {
            if (question.correct) {
                qCorrect ++;
            }
        }

        let outcome = 'Dobët!';
        if (qCorrect <= 1) {
            outcome = 'Dobët!';
        } else if (qCorrect < qTotal*0.6) {
            outcome = 'Kujdes!';
        } else if (qCorrect == qTotal) {
            outcome = 'Shkëlqyeshëm!'
        } else {
            outcome = 'Mjaftueshëm.'
        }

        this.elements.subtitle.text(`Pergjigje të sakta: ${qCorrect} nga ${qTotal}. ${outcome}`);
        this.elements.description.hide();
        this.elements.key_messages.show();
        this.elements.advice.show();
        this.elements.startButton.hide();
        this.elements.backButton.show();

    }


}