import $ from "jquery";
import Answer from "./answer";

export default class Question {
    constructor (category, data) {
        this.category = category;

        this.widget = $('<div class="question-dialog" data-stage="question" data-visibility="queued"></div>');

        this.elements = {
            question: $('<div class="question"></div>'),
            answers: $('<div class="answers-list"></div>'),
            advice: $('<div class="advice"></div>'),
            btnContinue:$('<button>VAZHDO <i class="fa fa-arrow-right"></i></button>')
        };

        this.data = {
            question:'',
            answers:[],
            correct:0,
            advice:''
        };

        this.correct = false;

        this.active = false;

        this.setData(data);
        this.buildDom();
        this.attachEvents();
    }

    setData(data) {
        console.log('setData',data);
        this.data.question = data.question;
        for (let i=1; i<=4; i++) {
            if (data['alternative_'+i] !== null) {
                this.data.answers.push(new Answer(this, data['alternative_'+i]));
            }
        }
        this.data.correct = data.correct_answer - 1;
        this.data.advice = data.advice;
    }

    buildDom() {
        this.widget.append(
            this.elements.question,
            this.elements.answers,
            this.elements.advice,
            this.elements.btnContinue
        );
        this.widget.attr('theme',this.category.categoryId);

        this.elements.question.text(this.data.question);
        for (let answer of this.data.answers) {
            this.elements.answers.append(answer.widget);
        }
        this.elements.advice.html(this.data.advice);

    }

    attachEvents() {
        this.elements.btnContinue.on('click',()=>{
           this.category.advance(this);
        });
    }

    isCorrect(answer) {
        return this.data.answers.indexOf(answer) === this.data.correct
    }

    canAnswer() {
        return this.active;
    }

    show() {
        this.active = true;
        this.widget.attr('data-visibility','active');
    }

    hide() {
        this.active = false;
        this.widget.attr('data-visibility','gone');
    }

    outcome(result) {
        this.active = false;

        if (result) {
            this.correct = true;
            setTimeout( () => {this.widget.attr('data-stage','advice');},500);
        } else {
            this.correct = false;
            setTimeout( () => {this.data.answers[this.data.correct].setCorrect();},500);
            setTimeout( () => {this.widget.attr('data-stage','advice');},3000);
        }
    }

}