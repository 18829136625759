import $ from "jquery";

import Category from "./category";

export default class Game {
    constructor(node) {
        this.node = node;

        this.attachEvents();
    }

    attachEvents() {
        const self = this;
        $(this.node).on('click', 'button', function() {
            self.hide();
            new Category($(this).attr('data-category-id'), self);
        });
    }

    show() {
        $(this.node).show();
    }

    hide() {
        $(this.node).hide();
    }

}